@import "~bootstrap/scss/bootstrap";
.textField {
  width: 250px !important;
}
.login_card {
  margin-top: 150px !important;
  margin-bottom: 50% !important;
  margin-left: 6%;
  text-align: center !important;
  // padding-top: 50px !important;
  padding-right: 4% !important;
  padding-left: 4% !important;
  width: 80%;
  align-content: center;
  justify-content: center;
  padding-bottom: 50px !important;
  // background-color: #273746 !important;
}
.login_background {
  background-color: #273746 !important;
  align-items: center;
  width: 100% !important;
  height: 100% !important;
}
.login_heading {
  margin-top: 10px !important;
  font-size: 100% !important;
  text-align: center !important;
}
.drawer_img {
  width: 130px !important;
  height: 130px !important;
  margin: auto !important;
}
.profile-name {
  text-align: center;
}
.drawer_text {
  text-decoration: none !important;
  color: black !important;
  font-size: 16px !important;
}
.drawer_icons {
  font-size: 16px !important;
}
.drawer_expansion_summary {
  text-decoration: none;
  background-color: white;
  padding-left: 18;
  font-size: 16px;
}
.table_cells {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.unit_details_add_btn {
  // float: right;
  display: inline-block;
  text-align: right !important;
}
.unit_details_title {
  display: inline-block;
}
//below webkit shows scroll bar everywhere
// ::-webkit-scrollbar {
//     -webkit-appearance: none;
//     width: 10px;
// }
// ::-webkit-scrollbar-thumb {
//     border-radius: 5px;
//     background-color: rgba(0,0,0,.5);
//     -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
// }

.landing_logo {
  height: 60px !important;
  width: 140px !important;
}
.appbarmenu {
  width: 100% !important;
  flex: 100% !important;
  // background-color: rgba(255,255,255, 1) !important;
}
.chat {
  position: fixed;
  z-index: 990;
  right: 2rem;
  bottom: 4rem;
  // text-align: center;
}
.link {
  text-decoration: none;
  color: whitesmoke !important;
}
.button {
  color: rgb(235, 215, 215) !important;
}
.carousel {
  width: 100% !important;
  height: 80vh !important;
}
.homeback {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/panda-machine-care.appspot.com/o/images%2Ftestimonials-bg.jpg?alt=media&token=1e6f52cd-1d93-4fd2-9bb1-005246d08bab");
  background-position: center;
  background-attachment: fixed;
  width: 100%;
}
.homecard1 {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/panda-machine-care.appspot.com/o/images%2Fcleaning.png?alt=media&token=ef3c6460-b868-43f0-bf3e-8212ad094a12");
  background-repeat: no-repeat;
  background-size: cover;
}
.homecard2 {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/panda-machine-care.appspot.com/o/images%2Ffleet-maintenance-.jpg?alt=media&token=44dd1e77-d879-4888-aba8-c6972b882bfa");
}
.homecard3 {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/panda-machine-care.appspot.com/o/images%2Fpreventive.jpeg?alt=media&token=37016cf8-8a08-44b7-bdeb-0fd86c7911c7");
}
.cardteam {
  margin-bottom: 6px;
  margin-top: 6px;
  background-color: #082f46 !important;
  color: wheat !important;
  // background-image: url("https://firebasestorage.googleapis.com/v0/b/panda-machine-care.appspot.com/o/images%2Fbgteam.jpg?alt=media&token=4f85b02d-4c2a-4be5-9e17-531ddc258e8a");
}
.footer {
  // filter: blur(1px);
  // -webkit-filter: blur(1px);
  // opacity: 0.8;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/panda-machine-care.appspot.com/o/images%2Ffooter3.jpg?alt=media&token=10cc5a8a-da97-4d9f-8a69-e9da03964bbd");
}
.social_links {
  margin-top: 20px !important;
}
.footli {
  display: inline-block;
  margin: 5px;
}
.footerlogo {
  height: 100px;
  color: white;
}
.footer_header {
  border-bottom: 1px solid #d2d4d6;
  padding-bottom: 60px;
  margin-bottom: 100px;
}
.footercont {
  margin-bottom: 2rem;
}
.footlist {
  font-size: medium;
  font-weight: bold;
  line-height: 28px;
  color: #3b6c99 !important;
  position: relative;
  padding-left: 0px;
  /* margin-bottom: 6px; */
  list-style: none;
}
.lin {
  text-decoration: none;
}
.footer_heading {
  font-size: x-large;
  font-weight: bold;
  color: #fff;
  /* font-weight: 400; */
  font-family: Roboto, sans-serif;
  margin-bottom: 29px;
}
.footer_headings {
  font-size: x-large;
  font-weight: bold;
  color: black;
  /* font-weight: 400; */
  font-family: Roboto, sans-serif;
  margin-bottom: 29px;
}
.centers {
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.8); /* Black w/opacity/see-through */
  // transform: translate(-50%, -50%);
  // z-index: 2;
  width: 100%;
  height: 100%;
}
.form-contact .form-control {
  border: 1px solid #e5e6e9;
  border-radius: 0;
  height: 48px;
  padding-left: 18px;
  font-size: 13px;
  box-shadow: 0px 2px 6px rgb(143, 143, 145);
  background: 0 0;
}
.contact-title {
  text-shadow: 1px 2px 6px rgb(70, 70, 71);
}
.form-contact textarea {
  border-radius: 0;
  height: 100% !important;
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-contact .form-group {
  margin-bottom: 30px;
}
.contact-info {
  margin-bottom: 25px;
}
.boxed-btn {
  background: #fff;
  color: #131313;
  display: inline-block;
  padding: 15px 40px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  border: 0;
  /* border: 1px solid #131313; */
  border-radius: 20px;
  letter-spacing: 3px;
  text-align: center;
  box-shadow: 0px 2px 6px rgb(143, 143, 145);
  color: #131313 !important;
  text-transform: uppercase;
}
.boxed-btn:hover {
  background: rgb(201, 200, 200);
  color: #131313;
  display: inline-block;
  padding: 15px 40px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  border: 0;
  /* border: 1px solid #131313; */
  border-radius: 20px;
  letter-spacing: 3px;
  text-align: center;
  box-shadow: 0px 2px 6px rgb(143, 143, 145);
  color: #ffffff !important;
  text-transform: uppercase;
}
.contact-info .media-body h3 {
  font-size: 16px;
  margin-bottom: 0;
  font-size: 16px;
  color: #2a2a2a;
}
.contact-info .media-body p {
  color: #8a8a8a;
}
.position {
  font-weight: lighter;
  font-size: small;
}
.description {
  color: yellow;
  font-size:xx-small;
  text-align: left;
  padding: 6px;
}
.headname{
  font-size: x-small;
}
